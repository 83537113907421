var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "tui tuim ui card item donation", attrs: { to: _vm.link } },
    [
      _c("div", { staticClass: "content spacer" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { class: "left floated meta " + _vm.item.status }, [
            _c("span", {
              staticClass: "status",
              domProps: { textContent: _vm._s(_vm.item.statusLabel) }
            })
          ]),
          _c("div", {
            staticClass: "right floated meta",
            domProps: { textContent: _vm._s(_vm.updatedAt) }
          })
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "amount" }, [
              _c("h3", {
                staticClass: "value",
                domProps: { textContent: _vm._s(_vm.amount) }
              }),
              _c("span", {
                staticClass: "frequency",
                domProps: { textContent: _vm._s(_vm.item.frequencyLabel) }
              })
            ]),
            _c("small", { staticClass: "currency" }, [_vm._v("BRL")]),
            false
              ? _c("t-status-color-bar", {
                  attrs: {
                    status: "7/10 sucessos",
                    items: [
                      { width: "65%", background: "#b4db75" },
                      { width: "15%", background: "#f9d96a" },
                      { width: "15%", background: "#fd9093" },
                      { width: "5%", background: "#e2e2e3" }
                    ]
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }