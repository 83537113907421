<template>
  <router-link :to="link" class="tui tuim ui card item donation">
    <div class="content spacer">
      <div class="content">
        <div :class="`left floated meta ${item.status}`">
          <span class="status" v-text="item.statusLabel" />
        </div>
        <div class="right floated meta" v-text="updatedAt" />
      </div>
      <div class="content">
        <div class="amount">
          <h3 class="value" v-text="amount" />
          <span class="frequency" v-text="item.frequencyLabel" />
        </div>
        <small class="currency">BRL</small>
        <!-- disabled component -->
        <t-status-color-bar v-if="false"
          status="7/10 sucessos"
          :items="[
            { width: '65%', background: '#b4db75' },
            { width: '15%', background: '#f9d96a' },
            { width: '15%', background: '#fd9093' },
            { width: '5%', background: '#e2e2e3' }
          ]"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import { formatDate, currencyFormat } from '@/helpers'

export default {
  name: 'TGroupDonationItem',
  components: {
    TStatusColorBar: () => import('@/components/status/color-bar.vue'),
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    link: ({ item }) => {
      return {
        name: 'donations.single',
        params: { id: item.id }
      }
    },
    amount: ({ item }) => `${currencyFormat(item.amount)}`,
    updatedAt: ({ item }) => formatDate(item.updatedAt, 'datetime')
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/sass/colors';

.tui.tuim.ui.card.item {
  background-color: $white;

  &.donation .content.spacer {
    padding: 10px 20px;

    .content {

      .status {
        font-size: 12px;
        text-transform: capitalize;
      }

      .amount {
        display: flex;
        margin-bottom: 0px;
        height: 20px;

        .frequency {
          margin-left: 5px;
          margin-top: 2px;
          font-weight: 700;
          color: $gray-600;
          opacity: 0.5;
        }
      }

      .currency {
        display: block;
        color: $gray-600;
        opacity: 0.5;
      }

      .value {
        font-size: 16px;
        line-height: 23px;
      }

      .meta.left {
        &.active::before {
          background-color: #74c0fc;
        }

        &.finalized::before {
          background-color: #5fe8be;
        }

        &.inactive::before {
          background-color: #ffa3a7;
        }
      }
    }
  }
}
</style>
